import React, { useState, useEffect } from "react";
import { Accordion, Container, Col } from "react-bootstrap"
import Modal from 'react-bootstrap/Modal'
import parse from "html-react-parser"
import CareersForm from "../../components/forms/career";
import "./JobListing.scss"

const JobListingModule = ( props ) => {

  const [show, setShow] = useState(false);
  var job_count = ""
                {props.module?.Job_List?.map((item, index) => {
                        return (
                          job_count = index + 1
                        );
                })}
    
  return (
    <div className="job-wrapper">
      <Container className="job-container">
        <div className="vacancy">
          <p className="job_count">{job_count}</p>
          <p className="description">{parse(props.description)}</p>
        </div>
       
        <div className="job-section">
         
                <Accordion flush>
                    {props?.module?.Job_List?.map((accordion, i) => (
                    <Accordion.Item eventKey={i} key={"acoordian" + i}>
                        <Accordion.Header>
                        <h4>{accordion?.Job_Role}</h4>
                        </Accordion.Header>
                        <div className="loc_dep_section">
                          <p className="job_loc">{accordion?.Job_Location}</p>
                          <p className="job_dept">{accordion?.Job_Department}</p>
                        </div>
                        <Accordion.Body>
                       { accordion?.Job_description &&
                        parse(accordion?.Job_description)}
                        <div class="apply-job-cta">
                         <a className="btn btn-banner" onClick={() => setShow(true)}>Apply for this Job</a>
                      </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    ))}
                </Accordion>
              
        
        </div>
        <Modal
            show={show}
            centered={true}
            onHide={() => setShow(false)}
            dialogClassName="modal-form-wrapper form-page-wrapper"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                <div className="form-page-heading">
                  <h2>Join the Team at<br></br>David Astburys</h2>
                  <p>Explore exciting career opportunities and take the next step in your professional journey with us.</p>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-page-form-details careers-form">
                <CareersForm />
              </div>
            </Modal.Body>
          </Modal>
      </Container>
    </div>
  )
}

export default JobListingModule
