import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import SidebarNewsletter from '../SidebarNewsletter/SidebarNewsletter'
import'./StaticContent.scss'
function StaticContent(props) {
    console.log("props",props)
    var contentClass = ""
    if(props.title === "Japanese Desk" || props.title === "Corporate Lettings" || props.title === "Embassy Lettings"){
        contentClass = 12
    }else{
        contentClass = 8
    }
    return (
        <section className='section-static-content grey-dual-white'>
            <Container className='intro-head'>
                <h1>{props?.title}</h1>
                {props?.intro &&
                        parse(props?.intro)}
            </Container>
            <Container className='content-wrapper'>
                <Row>
                    <Col lg={contentClass} className="inner-content">
                    {props?.content &&
                        parse(props?.content)}
                    </Col>
                    <Col lg={`1`} className="divider-col d-none d-lg-block"><span></span></Col>
                    <Col lg={`3`}>
                    {props.title !== "Japanese Desk" || props.title !== "Corporate Lettings" || props.title !== "Embassy Lettings" &&
                        <SidebarNewsletter />}
                    </Col>
                </Row>

            </Container>
        </section>
    )
}

export default StaticContent