import React from "react"
import { Container } from "react-bootstrap"
import "./BannerLanding.scss"
import parse from "html-react-parser"
import GetLink from "../functions/GetLink"
import ReviewSection from "../ReviewSection/ReviewSection"

const BannerLanding = ({ title, heading, description, layout, ctas, reviews }) => {
  const pageHasNoTitle = layout === "Landing_No_Title"

  return (
    <div className="banner-landing-wrapper">
      <Container className="banner-landing-container">
        <div className="banner-landing-content">
          {!pageHasNoTitle && <h4 className="title">{title}</h4>}
          <h1 className="heading">{heading}</h1>
          { title !== "Current Job Openings" && <div className="description">{parse(description)}</div>}
          {reviews == true && (
            <ReviewSection />
          )}

          {ctas?.length > 0 && 
            <div className="cta-section">
              {ctas.map(cta => (
                <>
                  {cta.CTA_Secondary_Link ? (
                    <a
                      href={cta.CTA_Secondary_Link}
                      target="_blank"
                      className="btn btn-banner"
                    >
                      {cta.CTA_Label}
                    </a>
                  ) : (
                    <GetLink
                      label={cta.CTA_Label}
                      link={cta.CTA_Link}
                      className="btn btn-banner"
                    />
                  )}
                </>
              ))}
            </div>
          }
        </div>
      </Container>
    </div>
  )
}

export default BannerLanding
