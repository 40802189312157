import React from "react";
import parse from "html-react-parser"
import { Link } from "gatsby";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "../SliderContent/SliderContent.scss"

let settings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
}
const SliderContent = (details) => {
 
  return (
    <div className="landlord-slide-section grey-bg">
      <Slider className="" {...settings}>


        {details?.details?.map((item, i) => {
          console.log("item",item)
          return (
            <div className="slide-details">
              <h2>{item?.Box_Title}</h2>

              <p className="slide-desc">
                {item?.Box_Description &&
                  parse(item?.Box_Description)}
              </p>
              <div className="slide-cta-sec">
                <Link to={item?.Box_CTA_Secondary_Link} className={`btn btn-banner`}>
                  {item?.Box_CTA_Label}
                </Link>
              </div>
            </div>
          )
        }


        )}
      </Slider>
    </div>
  )
}
export default SliderContent